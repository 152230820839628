/**
 * Video preloader
 */

import type { FunctionComponent, JSX } from 'preact'
import { createContext } from 'preact'
import { useCallback, useContext, useEffect, useState } from 'preact/hooks'

import { fetchMedia } from '../../utils/media.ts'

import { PAGE_GAME_IDLE_VIDEOS } from '../GameIdleVideo/constants.ts'
import { PAGE_GAME_SCORES_VIDEOS } from '../../pages/GameScores/constants.ts'
import { PAGE_INACTIVITY_VIDEO } from '../../pages/Inactivity/ItemContent/InactivityVideo/constants.ts'

/**
 * API
 */
type Api = (videoUrl: string) => Blob

/**
 * Context object
 */
const VideoContext = createContext<Api | null>(null)

/**
 * Hook
 */
export function usePreloadedVideo(): Api {
  const value = useContext(VideoContext)

  if (!value) {
    throw new Error('Video Provider Context has not been provided')
  }

  return value
}

// Note: Invalid refrences break build (Could not resolve ...)
const videoUrls: Array<string> = [
  PAGE_GAME_IDLE_VIDEOS,
  PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_400,
  PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_600,
  PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_800,
  PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_999,
  PAGE_INACTIVITY_VIDEO,
].flat(1)

/**
 * Provider
 */
export const VideoProvider: FunctionComponent<{
  /** Fallback component until videos are loaded */
  fallback?: JSX.Element
  /** Error fallback component */
  errorFallback?: JSX.Element
}> = ({ children, fallback = null, errorFallback = null }) => {
  // Video blobs map
  const [videosMap, setVideosMap] = useState<Map<string, Blob>>()

  // Preloading Error
  const [preloadingError, setPreloadingError] = useState<Error>()

  // Populate map
  useEffect(() => {
    const fetchAbortController = new AbortController()

    const entriesPromises = videoUrls
      // Unique
      .filter((currentValue, index, arr) => arr.indexOf(currentValue) === index)
      // Entry
      .map(async (videoUrl): Promise<[string, Blob]> => {
        const response = await fetchMedia(videoUrl, fetchAbortController.signal)
        const blob = await response.blob()

        return [videoUrl, blob]
      })

    /** @throws {MediaLoadingError} */
    Promise.all(entriesPromises)
      .then((videoEntries) => setVideosMap(new Map(videoEntries)))
      .catch(setPreloadingError)

    // Stop fetches
    return () => fetchAbortController.abort('unmount')
  }, [])

  // Note: API will be available to children when map is populated
  const api: Api = useCallback(
    (videoUrl) => {
      const blob = videosMap?.get(videoUrl)

      if (blob === undefined) {
        throw new RangeError('Invalid url')
      }

      return blob
    },
    [videosMap]
  )

  if (preloadingError) {
    return errorFallback
  }

  if (!videosMap) {
    return fallback
  }

  return <VideoContext.Provider value={api}>{children}</VideoContext.Provider>
}
