import type { FunctionComponent } from 'preact'
import { useEffect, useErrorBoundary } from 'preact/hooks'

import { SECONDS } from '../../constants/DateTime.ts'
import { useSystemStore } from '../../store/systemStore.ts'
import { useGameStore } from '../../store/gameStore.ts'
import { Message } from '../../pages/Message/Message.tsx'
import { formatPrefix } from '../../utils/console.ts'

/**
 * Handle uncaught errors
 * @note Handled errors are still emitted by preact in dev mode
 * @note Code uses Preact-specific useErrorBoundary hook, React backport: https://www.npmjs.com/package/react-use-error-boundary
 * @example Test throwing an error
 * ```ts
 * const [throwError, setThrowError] = useState<boolean>(false)
 *
 * if (throwError) {
 *   throw new Error('test')
 * }
 *
 * return <button onClick={() => setThrowError(true)}>{'Throw an Error'}</button>
 * ```
 */
export const ErrorBoundary: FunctionComponent<{ messageDuration?: number }> = ({ messageDuration = 5 * SECONDS, children }) => {
  const resetGame = useGameStore((state) => state.resetGame)
  const setPage = useSystemStore((state) => state.setPage)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [error, resetError] = useErrorBoundary((error: Error, _errorInfo) => {
    /* eslint-disable no-console */
    console.groupCollapsed(...formatPrefix('App Error'), error.toString())
    console.dir(error)
    console.groupEnd()
    /* eslint-enable no-console */

    resetGame()
    setPage('inactivity')
  })

  // Reset error after a timeout
  useEffect(() => {
    const resetErrorTimeoutId = window.setTimeout(resetError, messageDuration)

    return () => window.clearTimeout(resetErrorTimeoutId)
  }, [error, resetError, messageDuration])

  // Show message
  if (error) {
    return <Message text="Błąd aplikacji" error />
  }

  // No-op
  return <>{children}</>
}
