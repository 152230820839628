const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'short',
  timeStyle: 'medium',
}

/**
 * Format message prefix to include full date
 * @note DevTools have an option to prefix logs with timestamps (in hh:mm:ss.sss) which is not so helpful for long-running scripts
 * @note Not using styles, because chrome_debug.log entries are unformatted text
 * @note Format of chrome_debug.log: https://support.google.com/chrome/a/answer/6271282
 *
 * @example With info
 * ```ts
 * console.info(...formatPrefix('EventStream'), error)
 * ```
 * @example With group
 * ```ts
 * console.groupCollapsed(...formatPrefix('App Error'))
 * console.dir(error)
 * console.groupEnd()
 * ```
 */
export function formatPrefix(label: string): string[] {
  const now = new Date()

  // Use ISO8601 like format in local time zone
  const timestamp = now.toLocaleString(['sv-SE'], dateTimeFormatOptions)

  /*
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
  const timestamp = now.toISOString().replace(/^(.*)T(.*)Z$/, '$1 $2')
  */

  return [`[${label}]`, timestamp]
}
