import type { FunctionComponent } from 'preact'
import { useEffect, useState, useRef } from 'preact/hooks'

import type { ItemContentProps } from '../../Inactivity.tsx'
import { usePreloadedVideo } from '../../../../components/VideoProvider/VideoProvider.tsx'

import { PAGE_INACTIVITY_VIDEO } from './constants.ts'

/**
 * Inactivity invitation content
 * @note Video already fades in/ out
 */
export const InactivityVideo: FunctionComponent<ItemContentProps> = ({ isCurrent }) => {
  const getPreloadedVideo = usePreloadedVideo()

  const videoRef = useRef<HTMLVideoElement>(null)
  const playPromise = useRef<Promise<void>>(null)

  const [videoSrc, setVideoSrc] = useState<string | null>(null)

  // Use preloaded video
  useEffect(() => {
    const blob = getPreloadedVideo(PAGE_INACTIVITY_VIDEO)
    const objectUrl = blob && URL.createObjectURL(blob)

    setVideoSrc(objectUrl)

    return () => objectUrl && URL.revokeObjectURL(objectUrl)
  }, [getPreloadedVideo])

  // Handle video state
  useEffect(() => {
    const videoElement = videoRef.current

    // Source not set yet
    if (!videoElement || !videoSrc) {
      return
    }

    // Pause
    if (!isCurrent && playPromise.current) {
      return void playPromise.current.then(() => videoElement.pause())
    }

    // Rewind
    videoElement.currentTime = 0

    // Play
    if (!videoElement.paused) {
      return
    }

    playPromise.current = videoElement.play().catch((error: DOMException) => {
      switch (error.name) {
        // AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22
        // AbortError: The play() request was interrupted because video-only background media was paused to save power. https://goo.gl/LdLk22
        case 'AbortError':
          return
        // NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
        case 'NotAllowedError':
          videoElement.muted = true
          break
      }

      if (videoElement.paused) {
        return videoElement.play().catch(() => {})
      }
    })
  }, [isCurrent, videoSrc])

  return (
    <video
      ref={videoRef}
      className="bx-page bx-page--fullscreen bx-page--video"
      autoplay={false}
      controlsList="nodownload nofullscreen noremoteplayback"
      disablePictureInPicture
      loop={false}
      muted={true}
      poster={undefined}
      src={videoSrc ?? undefined}
      crossorigin="anonymous"
    />
  )
}
