import { FunctionComponent } from 'preact'
import { useEffect, useRef } from 'preact/hooks'

import { useSystemStore } from '../../store/systemStore.ts'

import './AppInfoPanel.css'

/**
 * App info panel
 * Visible on cursor movements
 */
export const AppInfoPanel: FunctionComponent<{ open: boolean }> = ({ open }) => {
  const { VITE_APP_VERSION: appVersion, VITE_HOSTING_IS_PULL_REQUEST: isPullRequest } = import.meta.env

  const elementRef = useRef<HTMLDListElement>(null)

  const accessToken = useSystemStore((state) => state.accessToken)
  const page = useSystemStore((state) => state.page)
  const setPage = useSystemStore((state) => state.setPage)

  // Toggle
  useEffect(() => {
    if (!elementRef.current) {
      return
    }

    const isOpen = elementRef.current.matches(':popover-open')

    if (isOpen !== open) {
      elementRef.current.togglePopover(open)
    }
  }, [open])

  return (
    <dl ref={elementRef} className={'bx-app__info'} popover="manual">
      <dt>{'App version'}</dt>
      <dd>{appVersion}</dd>

      <dt>{'Pull request'}</dt>
      <dd>{isPullRequest}</dd>

      <dt>{'Access token'}</dt>
      <dd>
        <button className="bx-button-link" onClick={() => setPage('sign-in')}>
          {JSON.stringify(accessToken !== null)}
        </button>
      </dd>

      <dt>{'Page'}</dt>
      <dd>{page}</dd>

      <dt>{'Network info'}</dt>
      <dd>
        D: {navigator.connection.downlink}Mbps, P: {navigator.connection.rtt}ms, T: {navigator.connection.effectiveType}
      </dd>
    </dl>
  )
}
