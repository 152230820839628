/**
 * @example
 * Importing all videos at once
 * ```js
 * const videoSrcUrlMap = import.meta.glob('./videos/*.mp4', { eager: true, as: 'url' })
 * const url = videoSrcUrlMap['./videos/animacja_1_all-h.264.mp4']
 * ```
 */
import animacja_2_2 from './videos/animacja_2_2-h.264.mp4'
import animacja_4_2 from './videos/animacja_4_2-h.264.mp4'
import animacja_5_3 from './videos/animacja_5_3-h.264.mp4'
import animacja_1_mis_konfetti_2 from './videos/animacja_1_mis_konfetti_2-h.264.mp4'
import animacja_2_kangur_rewelacja_konfetti_2 from './videos/animacja_2_kangur_rewelacja_konfetti_2-h.264.mp4'
import animacja_7_mis_dobrze from './videos/animacja_7_mis_dobrze-h.264.mp4'
import animacja_8_mysz_rewelacja_konfetti_2 from './videos/animacja_8_mysz_rewelacja_konfetti_2-h.264.mp4'
import animacja_9_kangur_dobrze from './videos/animacja_9_kangur_dobrze-h.264.mp4'
import animacja_10_kangur_kiepsko from './videos/animacja_10_kangur_kiepsko-h.264.mp4'
import animacja_11_mis_srednio_2 from './videos/animacja_11_mis_srednio_2-h.264.mp4'
import animacja_12_mysz_srednio from './videos/animacja_12_mysz_srednio-h.264.mp4'
import animacja_13_mysz_kiepsko from './videos/animacja_13_mysz_kiepsko-h.264.mp4'

// Score breakpoints
export const MAX_HIT_POWER = {
  LIGHT: 400,
  MEDIUM: 600,
  HARD: 800,
} as const

export const PAGE_GAME_SCORES_VIDEOS = {
  SCORE_LTE_400: [animacja_5_3, animacja_13_mysz_kiepsko, animacja_10_kangur_kiepsko],
  SCORE_LTE_600: [animacja_4_2, animacja_11_mis_srednio_2, animacja_12_mysz_srednio],
  SCORE_LTE_800: [animacja_2_2, animacja_7_mis_dobrze, animacja_9_kangur_dobrze],
  SCORE_LTE_999: [animacja_1_mis_konfetti_2, animacja_2_kangur_rewelacja_konfetti_2, animacja_8_mysz_rewelacja_konfetti_2],
}
