import { useEffect, useState } from 'preact/hooks'
import { type EventSourceMessage, fetchEventSource, EventStreamContentType } from '@microsoft/fetch-event-source'

import type { SerialNumber, AccessToken } from '../store/systemStore.ts'

import { ConnectError, EventSourceOpenFatalError, EventSourceOpenRetriableError, EventSourceCloseError } from '../utils/Error/index.ts'

import { SECONDS } from '../constants/DateTime.ts'
import { formatPrefix } from '../utils/console.ts'

type EventSourceReadyState = EventSource['readyState']

/**
 * Event stream processor
 */
export const useEventStream = (
  serialNumber: SerialNumber | null,
  accessToken: AccessToken | null,
  handleMessageEvent: (ev: EventSourceMessage) => void
): EventSourceReadyState | null => {
  const [eventSourceReadyState, setEventSourceReadyState] = useState<EventSourceReadyState | null>(null)

  /**
   * Connect to event source with current serial number and access token
   */
  useEffect(() => {
    if (!serialNumber) {
      return setEventSourceReadyState(null)
    }

    const url = new URL(`terminal/${serialNumber}/events`, import.meta.env.VITE_API_URL)
    const headers = new Headers({
      'Accept': EventStreamContentType,
      // ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      'X-Client-Version': import.meta.env.VITE_APP_VERSION,
    })

    const abortController = new AbortController()

    accessToken && url.searchParams.set('access-token', accessToken)

    // Note: The headers and signal RequestInit params are expected to be in fetchEventSource init
    const request = new Request(url, {
      method: 'GET',
      credentials: 'include',
    })

    setEventSourceReadyState(EventSource.CONNECTING)

    // Reference to last message
    let lastEventSourceMessage: EventSourceMessage | null = null

    fetchEventSource(request, {
      headers: Object.fromEntries(headers),
      signal: abortController.signal,

      // Do not disconnect on visibility change
      openWhenHidden: true,

      // Wrap TypeError
      async fetch(input, init) {
        const request = new Request(input, init)

        try {
          return await globalThis.fetch(request)
        } catch (typeError) {
          throw new ConnectError(request, { cause: typeError })
        }
      },

      /**
       * Open attempt
       * @throws {EventSourceFatalError}
       * @throws {EventSourceRetriableError}
       */
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          setEventSourceReadyState(EventSource.OPEN)
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new EventSourceOpenFatalError(response)
        } else {
          throw new EventSourceOpenRetriableError(response)
        }
      },

      /**
       * Error handler executed on:
       * - Error in handlers (onopen|onmessage|onclose)
       * - TypeError: Failed to fetch - [Re]connection failed
       * - TypeError: network error - Stream terminated (getBytes -> ReadableStreamDefaultReader.read) 'net::ERR_QUIC_PROTOCOL_ERROR 200 (OK)'
       * - After system/shutdown event with TypeError
       *
       * @throws {EventSourceFatalError} Rethrow to stop operation
       */
      onerror(error: ConnectError | EventSourceOpenFatalError | EventSourceOpenRetriableError | EventSourceCloseError | TypeError | Error) {
        // Collect network information at the time of error occurence
        const networkInfo = {
          online: navigator.onLine,
          downlink: navigator.connection.downlink,
          rtt: navigator.connection.rtt,
          effectiveType: navigator.connection.effectiveType,
        }

        // eslint-disable-next-line no-console
        console.info(...formatPrefix('EventStream'), error)

        // Stop
        if (error instanceof EventSourceOpenFatalError) {
          setEventSourceReadyState(EventSource.CLOSED)

          throw error
        }

        // Change state when previous successful connection
        setEventSourceReadyState(EventSource.CONNECTING)

        // API restart
        if (lastEventSourceMessage?.event === 'system/shutdown') {
          return 15 * SECONDS
        }

        // Retry after longer backoff
        if (error instanceof EventSourceOpenRetriableError) {
          return 15 * SECONDS
        }

        // Retry after longer backoff
        if (error instanceof EventSourceCloseError) {
          console.log(...formatPrefix('Network info'), JSON.stringify(networkInfo)) // eslint-disable-line no-console
          return 10 * SECONDS
        }

        // Assume network error
        // @link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
        if (error instanceof ConnectError || error instanceof TypeError) {
          console.log(...formatPrefix('Network info'), JSON.stringify(networkInfo)) // eslint-disable-line no-console
          return 5 * SECONDS
        }

        // Retry after default backoff
        return
      },

      /**
       * Triggered on when connection is closed server-side
       * @throws {EventSourceCloseError} For automatic reconnection
       */
      onclose() {
        throw new EventSourceCloseError()
      },

      /**
       * Message handler
       * @note Unlike with native EventSource, id with a '' value is not persisted across messages
       */
      onmessage(eventSourceMessage) {
        // Set last
        lastEventSourceMessage = eventSourceMessage

        handleMessageEvent(eventSourceMessage)
      },
    })
      // Error thrown from within onerror handler to stop reconnections
      .catch((_error) => {})

    // Note: Usually State immediately switcing to CONNECTING without a flash
    abortController.signal.addEventListener('abort', () => setEventSourceReadyState(EventSource.CLOSED))

    // Close event source for given serial number
    // Note: This resolves fetchEventSource promise, but doesn't trigger any handler (onclose|onerror)
    return () => abortController.abort()
  }, [serialNumber, accessToken, handleMessageEvent])

  return eventSourceReadyState
}
