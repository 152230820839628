import { ConnectError, MediaLoadingError } from './Error/index.ts'

/**
 * Fetch url
 * Decode to ArrayBuffer or Blob as you wish
 * @throws {Error}
 */
export async function fetchMedia(url: string, signal?: AbortSignal): Promise<Response> {
  const request = new Request(url, {
    method: 'GET',
    signal,
  })

  let response: Response

  try {
    response = await globalThis.fetch(request)
  } catch (typeError) {
    throw new ConnectError(request, { cause: typeError as TypeError })
  }

  // Invalid status code
  if (!response.ok) {
    throw new MediaLoadingError(request, response)
  }

  return response
}
