import type { FunctionComponent } from 'preact'

import type { ItemContentProps } from '../Inactivity.tsx'
import { Logo } from '../../../components/Logo/Logo.tsx'

/**
 * Inactivity Logo content
 */
export const LogoItemContent: FunctionComponent<ItemContentProps> = () => (
  <div className="bx-page">
    <Logo />
  </div>
)
