import type { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { SECONDS } from '../../constants/DateTime.ts'
import { useGameStore } from '../../store/gameStore.ts'
import { getRandomItem } from '../../utils/array.ts'
import { GameVideo } from '../../components/GameVideo/GameVideo.tsx'
import { GameIdleVideo } from '../../components/GameIdleVideo/GameIdleVideo.tsx'
import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { usePreloadedVideo } from '../../components/VideoProvider/VideoProvider.tsx'
import { ConfettiOverlay } from '../../components/ConfettiOverlay/ConfettiOverlay.tsx'

import { MAX_HIT_POWER, PAGE_GAME_SCORES_VIDEOS } from './constants.ts'

import { AUDIO } from '../../constants/Audio.ts'

import './GameScores.css'

/**
 * Show full video, then scores
 * Show idle video when page is shown longer than given threshold
 *
 * @note Max duration of scores animation on LED panel is 7s
 *       May use as a fall-back in case of missed 'game/scores-displayed' event
 */
export const GameScores: FunctionComponent = () => {
  // Note: Doesn't toggle for loops
  const [videoEnded, setVideoEnded] = useState<boolean>(false)
  const [isIdle, setIsIdle] = useState<boolean>(false)

  // Note: Should always be non-null
  const gameScore = useGameStore((state) => state.gameScore)
  const credits = useGameStore((state) => state.credits)
  const isGameScoreDisplayed = useGameStore((state) => state.isGameScoreDisplayed)
  const setIsGameScoreDisplayed = useGameStore((state) => state.setIsGameScoreDisplayed)
  const isGameScoreNewMax = useGameStore((state) => state.playerScoresIsNewMax)

  const playAudio = useAudioMixer()
  const getPreloadedVideo = usePreloadedVideo()

  const [videoSrc, setVideoSrc] = useState<string | null>(null)

  // Set video source
  useEffect(() => {
    if (gameScore === null) {
      return
    }

    const videoBlob = getPreloadedVideo(getRandomItem(getVideoSrcs(gameScore)))
    const videoObjectUrl = URL.createObjectURL(videoBlob)

    setVideoSrc(videoObjectUrl)

    return () => URL.revokeObjectURL(videoObjectUrl)
  }, [gameScore, getPreloadedVideo])

  // Set idle state
  useEffect(() => {
    if (!credits || !isGameScoreDisplayed) {
      return
    }

    const timer = window.setTimeout(() => setIsIdle(true), 10 * SECONDS)

    return () => window.clearTimeout(timer)
  }, [credits, isGameScoreDisplayed])

  // Manually set state when game/scores-displayed isn't received
  useEffect(() => {
    // Cancel timer
    if (!gameScore || isGameScoreDisplayed) {
      return
    }

    // Delay for max score 999 + some network delay (0.5s)
    // Should be 3s..7s depending on score; see mock ApiEvents
    const delay = 7.5 * SECONDS
    const timer = window.setTimeout(() => setIsGameScoreDisplayed(true), delay)

    return () => window.clearTimeout(timer)
  }, [gameScore, isGameScoreDisplayed, setIsGameScoreDisplayed])

  // Play audio when game score is displayed
  // Note: sounds are muted by board during score animation on LED panel
  useEffect(() => {
    if (!gameScore || !isGameScoreDisplayed) {
      return
    }

    if (gameScore <= MAX_HIT_POWER.LIGHT) {
      return void playAudio(AUDIO.PAGE_GAME_SCORES.SCORE_LTE_400)
    }

    if (gameScore <= MAX_HIT_POWER.MEDIUM) {
      return void playAudio(AUDIO.PAGE_GAME_SCORES.SCORE_LTE_600)
    }

    if (gameScore <= MAX_HIT_POWER.HARD) {
      return void playAudio(AUDIO.PAGE_GAME_SCORES.SCORE_LTE_800)
    }

    const [sound1, sound2] = AUDIO.PAGE_GAME_SCORES.SCORE_LTE_999

    playAudio(sound1).then((ts) => ts.addEventListener('ended', () => void playAudio(sound2)))
  }, [gameScore, isGameScoreDisplayed, playAudio])

  // Idle animation
  if (isIdle) {
    return <GameIdleVideo />
  }

  // Score result animation
  if (videoSrc && (videoEnded === false || isGameScoreDisplayed === false)) {
    return <GameVideo src={videoSrc} onEnded={() => setVideoEnded(true)} onError={() => setVideoEnded(true)} />
  }

  return (
    <>
      <section className="bx-page bx-page--game-scores">
        <div>
          {isGameScoreNewMax ? (
            <h2 className="bx-action-heading bx-action-heading--small bx-animation bx-animation--slide-left">{'Twój nowy rekord'}</h2>
          ) : (
            <h2 className="bx-action-heading bx-action-heading--small bx-animation bx-animation--slide-left">{'Your score'}</h2>
          )}
          <h3 className="bx-action-heading bx-action-heading--large bx-animation bx-animation--slide-right">{gameScore ?? '—'}</h3>
        </div>

        <GamePanels />
      </section>
      {isGameScoreNewMax && <ConfettiOverlay className="bx-page bx-page--fullscreen" />}
    </>
  )
}

/**
 * Choose animations based on score
 * Boxer machine works with ranges: 1...400...800...999
 * @TODO Sounds use inclusive ranges
 */
function getVideoSrcs(gameScore: number): string[] {
  if (gameScore < MAX_HIT_POWER.LIGHT) {
    return PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_400
  }

  if (gameScore < MAX_HIT_POWER.MEDIUM) {
    return PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_600
  }

  if (gameScore < MAX_HIT_POWER.HARD) {
    return PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_800
  }

  return PAGE_GAME_SCORES_VIDEOS.SCORE_LTE_999
}
