import TransferError from './TransferError.ts'

/**
 * Media loading error
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/error_event
 */
export default class MediaLoadingError extends TransferError {
  public name = 'MediaLoadingError'

  constructor(
    request: Request,
    public response: Response
  ) {
    super(request, 'An error occurred while fetching the media data')

    Object.setPrototypeOf(this, MediaLoadingError.prototype)
  }
}
