/**
 * [Multi track]{@link https://github.com/mdn/webaudio-examples/tree/main#multi-track}
 * [Gain Node]{@link https://developer.mozilla.org/en-US/docs/Web/API/GainNode}
 * {@link https://developer.chrome.com/blog/autoplay/#web-audio}
 *
 * Souce -> GainNode -> Destination
 */
import type { FunctionComponent, JSX } from 'preact'
import { createContext } from 'preact'
import { useCallback, useContext, useEffect, useMemo, useState } from 'preact/hooks'

import { AUDIO } from '../../constants/Audio.ts'
import { fetchMedia } from '../../utils/media.ts'

/**
 * API
 */
type Api = (audioUrl: string, options?: Omit<AudioBufferSourceOptions, 'buffer'>) => Promise<AudioBufferSourceNode>

/**
 * Context object
 */
const AudioMixerContext = createContext<Api | null>(null)

/**
 * Hook
 */
export function useAudioMixer(): Api {
  const value = useContext(AudioMixerContext)

  if (!value) {
    throw new Error('Audio Mixer Context has not been provided')
  }

  return value
}

/** Preload audio URLs */
const audioUrls: Array<string> = [
  // Events
  AUDIO.EVENT_PLAYER_SIGN_IN,
  // Pages
  AUDIO.PAGE_CONFIGURATION,
  AUDIO.PAGE_SESSION_END,
  AUDIO.PAGE_GLOBAL_SCORES,
  AUDIO.PAGE_GAME_IDLE,
  // Pages: Game scores
  AUDIO.PAGE_GAME_SCORES.SCORE_LTE_400,
  AUDIO.PAGE_GAME_SCORES.SCORE_LTE_600,
  AUDIO.PAGE_GAME_SCORES.SCORE_LTE_800,
  AUDIO.PAGE_GAME_SCORES.SCORE_LTE_999,
].flat(2)

/**
 * Provider
 */
export const AudioMixerProvider: FunctionComponent<{
  /** Master volume level */
  masterVolume?: number
  /** Fallback component until sounds are loaded */
  fallback?: JSX.Element
  /** Error fallback component */
  errorFallback?: JSX.Element
}> = ({ children, masterVolume = 0.5, fallback = null, errorFallback = null }) => {
  // Audio buffers map
  const [audioBuffersMap, setAudioBuffersMap] = useState<Map<string, AudioBuffer>>()

  // Preloading error
  const [preloadingError, setPreloadingError] = useState<Error>()

  // Create Audio Context
  const audioCtx: AudioContext = useMemo(() => new AudioContext({}), [])

  // Create a gain node and set it's gain value
  const gainNode: GainNode = useMemo(() => {
    const gainNode = new GainNode(audioCtx, { gain: masterVolume })

    gainNode.connect(audioCtx.destination)

    return gainNode
  }, [audioCtx, masterVolume])

  // Preload audio buffers
  useEffect(() => {
    const fetchAbortController = new AbortController()

    const entriesPromises = audioUrls
      // Unique
      .filter((audioUrl, index, arr) => arr.indexOf(audioUrl) === index)
      // Defined sound
      .filter((audioUrl): audioUrl is string => audioUrl !== null)
      // Entry
      .map(async (audioUrl): Promise<[string, AudioBuffer]> => {
        const response = await fetchMedia(audioUrl, fetchAbortController.signal)
        const arrayBuffer = await response.arrayBuffer()
        const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer)

        return [audioUrl, audioBuffer]
      })

    /** @throws {MediaLoadingError|DOMException} - Unable to decode audio data */
    Promise.all(entriesPromises)
      .then((soundEntries) => setAudioBuffersMap(new Map(soundEntries)))
      .catch(setPreloadingError)

    return () => {
      // Stop fetches
      fetchAbortController.abort('unmount')
      audioCtx.close()
    }
  }, [audioCtx])

  // Play API
  const api: Api = useCallback(
    async (audioUrl, options) => {
      const audioBuffer = audioBuffersMap?.get(audioUrl)

      if (audioBuffer === undefined) {
        throw new RangeError('Invalid url')
      }

      // Check if context is in suspended state (autoplay policy)
      if (audioCtx.state === 'suspended') {
        // Note: Promise may be suspended by autoplay policy
        await audioCtx.resume()
      }

      // Create a buffer
      const trackSource = new AudioBufferSourceNode(audioCtx, {
        buffer: audioBuffer,
        ...options,
      })

      trackSource.connect(gainNode)
      trackSource.start(0, 0)

      trackSource.addEventListener('ended', () => trackSource.disconnect(gainNode))

      return trackSource
    },
    [audioBuffersMap, audioCtx, gainNode]
  )

  if (preloadingError) {
    return errorFallback
  }

  if (!audioBuffersMap) {
    return fallback
  }

  return <AudioMixerContext.Provider value={api}>{children}</AudioMixerContext.Provider>
}
