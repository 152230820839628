import type { FunctionComponent } from 'preact'

import type { ItemContentProps } from '../../Inactivity.tsx'

import noHeadHittingImageUrl from './images/no-head-hitting.svg'
import noKickingImageUrl from './images/no-kicking.svg'
import noRunningImageUrl from './images/no-running.svg'
import noSidePunchingImageUrl from './images/no-side-punching.svg'
import properPunchImageUrl from './images/proper-punch.svg'
import safetyZoneImageUrl from './images/safety-zone.svg'

import './Warning.css'

const items: ReadonlyArray<{ alt: string; img: string; caption: string }> = [
  { alt: 'Safety zone', img: safetyZoneImageUrl, caption: 'Strefa\nbezpieczeństwa' },
  { alt: 'Proper punch', img: properPunchImageUrl, caption: 'Poprawne\nuderzenie' },
  { alt: 'No running', img: noRunningImageUrl, caption: 'Nie biegać' },
  { alt: 'No head hitting', img: noHeadHittingImageUrl, caption: 'Nie uderzać\ngłową' },
  { alt: 'No side punching', img: noSidePunchingImageUrl, caption: 'Nie uderzać\nz boku' },
  { alt: 'No kicking', img: noKickingImageUrl, caption: 'Nie kopać' },
]

/**
 * Warning content
 */
export const WarningItemContent: FunctionComponent<ItemContentProps> = () => (
  <div className="bx-page">
    <p className="bx-warning-headline">{'Uwaga'}</p>

    <div className="bx-warning-grid">
      {items.map((item, index) => (
        <figure key={index} className="bx-warning-item">
          <img className="bx-warning-item__image" src={item.img} alt={item.alt} />
          <figcaption className="bx-warning-item__caption">{item.caption}</figcaption>
        </figure>
      ))}
    </div>
  </div>
)
