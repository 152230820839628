import type { FunctionComponent, JSX } from 'preact'

/**
 * Note: Flash of blank page before video is caused by time to load video (even in PWA mode)
 * Using poster fixes that, however there is a time required to load poster
 */
export const GameVideo: FunctionComponent<JSX.VideoHTMLAttributes<HTMLVideoElement>> = (props) => {
  return (
    <video
      className="bx-page bx-page--fullscreen bx-page--video"
      autoPlay={true}
      controlsList="nodownload nofullscreen noremoteplayback"
      disablePictureInPicture
      muted={true}
      poster={undefined}
      crossorigin="anonymous"
      {...props}
    />
  )
}
