import backgroundMusic from '../assets/sounds/coding-night-112186-v9-mono.mp3'
import signInAudio from '../assets/sounds/Welcome-hero-3.mp3'
import ovation1_1 from '../assets/sounds/Owacje-1_1.mp3'
import ovation1_2 from '../assets/sounds/Owacje-1_2.mp3'
import ovation2 from '../assets/sounds/Owacje-2.mp3'
import thatWasGreat from '../assets/sounds/That-Was-Great_9.mp3'
import jingle1_1 from '../assets/sounds/Jingle_1_1.mp3'
import jingle1_2 from '../assets/sounds/Jingle_1_2.mp3'
import jingle1_3 from '../assets/sounds/Jingle_1_3.mp3'
import jingle2_2 from '../assets/sounds/Jingle_2_2.mp3'
import jingle2_3 from '../assets/sounds/Jingle_2_3.mp3'
import jingle3_3 from '../assets/sounds/Jingle_3_3.mp3'
import whereAreYou3 from '../assets/sounds/Where-Are-You_3.mp3'
import yourMove4 from '../assets/sounds/Your-Move_4.mp3'
import areYouReady1 from '../assets/sounds/Are-You-Ready_1.mp3'

// List of random items
type RandomItems<T = string> = T[]
// Pair of sounds played in sequence
type SequencePair<T = string> = [T, T]

/**
 * @note Machine plays native sounds for these events:
 *       - 'game/start'
 *       - 'game/scores'
 *       - 'info/no-credits'
 */
export const AUDIO = {
  // Events
  EVENT_SESSION_START: null,
  EVENT_SESSION_END: null,
  EVENT_PLAYER_SIGN_IN: signInAudio,
  EVENT_PLAYER_SIGN_OUT: null,
  EVENT_GAME_CREDITS: null,
  EVENT_GAME_START: null,
  EVENT_GAME_READY: null,
  EVENT_GAME_SCORES: null,
  EVENT_INFO_NO_CREDITS: null,
  // Pages
  PAGE_CONFIGURATION: backgroundMusic,
  PAGE_GLOBAL_SCORES: jingle3_3,
  PAGE_SESSION_END: [ovation1_1, ovation1_2, ovation2] satisfies RandomItems,
  PAGE_GAME_IDLE: [
    [whereAreYou3, jingle2_2],
    [yourMove4, jingle1_3],
    [areYouReady1, jingle1_3],
  ] satisfies RandomItems<SequencePair>,
  // Pages: Game scores
  PAGE_GAME_SCORES: {
    SCORE_LTE_400: jingle1_1,
    SCORE_LTE_600: jingle1_2,
    SCORE_LTE_800: jingle2_3,
    SCORE_LTE_999: [thatWasGreat, jingle2_3] satisfies SequencePair,
  },
} as const
