import type { FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import { GameVideo } from '../GameVideo/GameVideo.tsx'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { usePreloadedVideo } from '../VideoProvider/VideoProvider.tsx'
import { getRandomItem } from '../../utils/array.ts'

import { AUDIO } from '../../constants/Audio.ts'
import { PAGE_GAME_IDLE_VIDEOS } from './constants.ts'

export const GameIdleVideo: FunctionComponent = () => {
  const playAudio = useAudioMixer()
  const getPreloadedVideo = usePreloadedVideo()

  const videoBlobs: Blob[] = useMemo(() => shuffleItems(PAGE_GAME_IDLE_VIDEOS).map(getPreloadedVideo), [getPreloadedVideo])

  const [videoSrc, setVideoSrc] = useState<string>()

  useEffect(() => {
    const [sound, jingle] = getRandomItem(AUDIO.PAGE_GAME_IDLE)

    playAudio(sound).then((ts) => ts.addEventListener('ended', () => void playAudio(jingle)))
  }, [playAudio])

  // Create media source of combined videos
  useEffect(() => {
    const mediaSource = new MediaSource()
    const videoObjectUrl = URL.createObjectURL(mediaSource)

    setVideoSrc(videoObjectUrl)

    mediaSource.addEventListener('sourceopen', async () => {
      const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.640028"')

      // Required for multiple sources
      sourceBuffer.mode = 'sequence'

      // Append in sequence
      for (const blob of videoBlobs) {
        await appendBufferAsync(sourceBuffer, await blob.arrayBuffer()).catch(() => {})
      }

      // Signal the end of the stream
      mediaSource.endOfStream()
    })

    return () => URL.revokeObjectURL(videoObjectUrl)
  }, [videoBlobs])

  return <GameVideo loop={true} src={videoSrc} />
}

/**
 * Shuffle array
 * @link https://stackoverflow.com/a/46545530/1012616
 */
function shuffleItems<T>(items: T[]): T[] {
  return items
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

/**
 * SourceBuffer.appendBufferAsync ponyfill
 * @link https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/appendBufferAsync
 * @link https://developer.mozilla.org/en-US/docs/Web/API/MediaSource#complete_basic_example
 * @throws {DOMException} - QuotaExceededError - https://developer.chrome.com/blog/quotaexceedederror
 */
async function appendBufferAsync(mediaSourceBuffer: SourceBuffer, arrayBuffer: BufferSource): Promise<void> {
  const abortController = new AbortController()

  try {
    await new Promise<Event>((resolve, reject) => {
      mediaSourceBuffer.addEventListener('updateend', resolve, { signal: abortController.signal })
      mediaSourceBuffer.addEventListener('error', reject, { signal: abortController.signal })
      mediaSourceBuffer.appendBuffer(arrayBuffer)
    })
  } catch (error) {
    // No-op
  } finally {
    abortController.abort()
  }
}
